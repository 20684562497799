import type { WebsiteConfig } from '@SHARED/core/entities/WebsiteConfig';
import type { App } from 'vue';

import type {
	ScriptLoaderOptions,
	RecaptchaOptionsInput
} from '@nargarath/vue-recaptcha';
import {
	createPlugin,
	defineScriptLoader,
	toQueryString
} from '@nargarath/vue-recaptcha';
import { warn as vueWarn } from 'vue';
import * as Sentry from '@sentry/vue';

type GoogleIntegrationsScript = { innerHTML: string } | { src: string };

export default defineNuxtPlugin(({ vueApp }) => {
	const websiteConfig = useState<WebsiteConfig>('websiteConfig');

	if (websiteConfig.value.integrations.googleAnalyticsId) {
		const { initialize, enableAnalytics } = useGtag();

		initialize(websiteConfig.value.integrations.googleAnalyticsId);
		enableAnalytics(websiteConfig.value.integrations.googleAnalyticsId);
	}

	const googleTagManagerScript = computed<GoogleIntegrationsScript[]>(() => {
		if (!websiteConfig.value.integrations.googleTagManagerId) return [];

		return [
			{
				innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${websiteConfig.value.integrations.googleTagManagerId}');`,
				key: 'GTMScript'
			}
		];
	});

	const googleTagManagerNoScript = computed<GoogleIntegrationsScript[]>(() => {
		if (!websiteConfig.value.integrations.googleTagManagerId) return [];

		return [
			{
				innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=${websiteConfig.value.integrations.googleTagManagerId}" height="0" width="0" style="display: none; visibility: hidden"></iframe>`,
				key: 'googleTagManagerNoScript'
			}
		];
	});

	const recaptchaV2Key = websiteConfig.value.integrations.recaptchaV2Key || '';

	defineRecaptchaPlugin({ vueApp, recaptchaV2Key });

	if (!recaptchaV2Key) {
		const domain = useState('domain');

		Sentry.getClient()?.sendEvent({
			message: `Recaptcha v2 key not found in website config or public config for ${domain.value}`,
			level: 'warning'
		});
	}

	const scripts = computed(() => [
		{
			src: `https://www.google.com/recaptcha/enterprise.js?render=${recaptchaV2Key}&onload=__vueRecaptchaLoaded`,
			defer: true,
			key: 'recaptcha'
		},
		...googleTagManagerScript.value
	]);

	const noScripts = computed(() => [...googleTagManagerNoScript.value]);

	const isWebsiteUsingCookies = computed<boolean>(() => {
		const { googleAnalyticsId, googleTagManagerId } =
			websiteConfig.value.integrations;

		return !!(googleAnalyticsId || googleTagManagerId);
	});

	useState('isWebsiteUsingCookies', () => isWebsiteUsingCookies);

	useHead({
		script: () => scripts.value,
		noscript: () => noScripts.value
	});
});

// * Foi necessário copiar o plugin do recaptcha enterprise do vue-recaptcha para
// * que a lib funcione normalmente usando a chave vinda do banco de dados,
// * pois utilizar o VueRecaptchaPlugin exportado pela lib não funcionou
function defineRecaptchaPlugin({
	vueApp,
	recaptchaV2Key
}: {
	vueApp: App;
	recaptchaV2Key: string;
}) {
	function warn(msg: string, ...params: unknown[]) {
		vueWarn(`[vue-recaptcha]: ${msg}`, ...params);
	}

	function invariant(condition: unknown, msg: string): asserts condition {
		if (!condition) {
			warn(msg);
			throw new Error(`Invariant violation: ${msg}`);
		}
	}

	function getEnterpriseRecaptcha() {
		invariant(
			window.grecaptcha.enterprise,
			'Please load enterprise recaptcha script first'
		);
		return window.grecaptcha.enterprise;
	}

	const unheadScriptLoader = defineScriptLoader(
		(options: ScriptLoaderOptions) => () => {
			useHead({
				link: [
					{
						key: 'vue-recaptcha-google',
						rel: 'preconnect',
						href: options.useRecaptchaNet
							? 'https://www.recaptcha.net'
							: 'https://www.google.com'
					},
					{
						key: 'vue-recaptcha-gstatic',
						rel: 'preconnect',
						href: 'https://www.gstatic.com',
						crossorigin: ''
					}
				],
				script: [
					{
						key: 'vue-recaptcha',
						src: `${options.recaptchaApiURL}?${toQueryString(options.params)}`,
						async: true,
						defer: true,
						nonce: options.nonce
					}
				]
			});
		}
	);

	vueApp.use(
		createPlugin([
			{ scriptLoader: unheadScriptLoader, getRecaptcha: getEnterpriseRecaptcha }
		]),
		{
			v2SiteKey: recaptchaV2Key || 'no-key-defined'
		} satisfies RecaptchaOptionsInput
	);
}
