<template>
	<section
		id="contato"
		class="section-wrapper"
		:class="{ parallax: isParallaxEnabled }"
		:data-background-position="backgroundSideVariant"
		:data-has-background-image="!!backgroundImageUrl"
	>
		<div class="overlay">
			<div
				class="contact-background"
				:data-has-background-image="!!backgroundImage"
			></div>
			<PaddingContainer
				class="contact-content"
				:padding="padding"
				component="div"
			>
				<template v-if="shouldShowContactDetails">
					<div class="contact-information">
						<h2 class="contact-heading">Detalhes para contato</h2>
						<span class="contact-company-label">Equipe {{ company.name }}</span>
						<address class="company-information-items-wrapper">
							<CompanyInformationItem
								v-for="(link, index) in contactItems"
								:key="`contact-information-item-${index}`"
								:label="link.label"
								:content="link.content"
								:icon="link.icon"
								:icon-color="props.companyInformationIconsColor"
								:url="link.url"
							/>
						</address>
					</div>
					<div class="contact-column-separator"></div>
				</template>

				<form class="contact-form" @submit.prevent="submitForm">
					<span class="contact-form-title">{{ title }}</span>
					<FormField
						label="Nome"
						autocomplete="name"
						label-class="contact-label default-font"
						:error-message="vuelidate.name.$errors[0]?.$message"
					>
						<template #default="{ id }">
							<InputField
								:id="id"
								v-model="formData.name"
								type="text"
								name="name"
								class="contact-input default-font"
								@change="vuelidate.name.$touch"
							/>
						</template>
					</FormField>
					<FormField
						label="E-mail"
						autocomplete="email"
						label-class="contact-label default-font"
						:error-message="vuelidate.email.$errors[0]?.$message"
					>
						<template #default="{ id }">
							<InputField
								:id="id"
								v-model="formData.email"
								type="email"
								name="email"
								class="contact-input default-font"
								@change="vuelidate.email.$touch"
							/>
						</template>
					</FormField>

					<!-- TODO: comentado até liberarmos o telefone com countryCode
          <FormField
						label="Telefone"
						autocomplete="phone"
						label-class="contact-label default-font"
						:error-message="vuelidate.phone.$errors[0]?.$message"
					>
						<template #default="{ id }">
							<PhoneInput
								:id="id"
								v-model="formData.phone"
								class="phone-input-container"
								input-class="contact-input default-font"
								select-class="phone-select default-font"
								@update:mask="updateMask"
								@change="vuelidate.phone.$touch"
							/>
						</template>
					</FormField> -->

					<FormField
						label="Telefone"
						autocomplete="phone"
						label-class="contact-label default-font"
						:error-message="vuelidate.phone.$errors[0]?.$message"
					>
						<template #default="{ id }">
							<InputField
								:id="id"
								v-model="formData.phone.number"
								:value="formData.phone.number"
								type="text"
								name="phone"
								:mask="phoneMasks"
								class="contact-input default-font"
								@change="vuelidate.phone.$touch"
							/>
						</template>
					</FormField>

					<FormField
						label="Mensagem"
						autocomplete="message"
						label-class="contact-label default-font"
						:error-message="vuelidate.message.$errors[0]?.$message"
					>
						<template #default="{ id }">
							<InputField
								:id="id"
								v-model="formData.message"
								name="message"
								tag="textarea"
								rows="3"
								:value="formData.message"
								class="contact-textarea default-font"
								@change="vuelidate.message.$touch"
							/>
						</template>
					</FormField>

					<RecaptchaMessage class="contact-recaptcha-message" />

					<div>
						<ButtonBlock
							text="Enviar"
							:background-color="btnBackgroundColor"
							:text-color="btnTextColor"
							:is-outlined="false"
							tag="button"
							type="submit"
							gap="0.75rem"
							:disabled="isSubmittingForm"
						>
							<template #after>
								<LoadingIcon v-show="isSubmittingForm" class="loading" />
							</template>
						</ButtonBlock>
					</div>
					<div ref="recaptchaRoot"></div>
				</form>
			</PaddingContainer>
		</div>
	</section>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core';
import { email, helpers, required } from '@vuelidate/validators';

import type { Phone } from '@SHARED/components/molecules/PhoneInput.vue';
import type { Company } from '@SHARED/core/entities/Company';
import type { ContactSection } from '@SHARED/core/entities/sections/ContactSection';
import type {
	Color,
	WebsiteConfig,
	WebsiteStyles
} from '@SHARED/core/entities/WebsiteConfig';
import type { RegisterNewDealParams } from '@SHARED/core/ports/services/DealRegistrationService';
import type { CssSize } from '@SHARED/utils/helperTypes';
import type { CompanyInformationItem as ICompanyInformationItem } from '@SHARED/presenters/CompanyInformationPresenter';

import { useNotyf } from '@SHARED/composables/useNotyf';
import { usePhoneInputValidation } from '@SHARED/composables/usePhoneInputValidation';
import { useGoogleAnalytics } from '@SHARED/composables/useGoogleAnalytics';
import {
	useFormWithRecaptcha,
	type FormSubmitionFunctionParams
} from '@SHARED/composables/useFormWithRecaptcha';
import { CompanyInformationPresenter } from '@SHARED/presenters/CompanyInformationPresenter';
import { getCSSColorVar } from '@SHARED/utils/style';
import { copyObject } from '@SHARED/utils';
import { concatUrlPath } from '@SHARED/utils/url';
import { PHONE_SERVICE } from '@SHARED/utils/vueProvidersSymbols';
import { useContactConfig } from '@SHARED/composables/useContactConfig';

import CompanyInformationItem from '@SHARED/components/atoms/CompanyInformationItem.vue';
import InputField from '@SHARED/components/atoms/InputField.vue';
import ButtonBlock from '@SHARED/components/blocks/ButtonBlock.vue';
import FormField from '@SHARED/components/molecules/FormField.vue';
import PaddingContainer from '@SHARED/components/molecules/PaddingContainer.vue';
import RecaptchaMessage from '@SHARED/components/molecules/RecaptchaMessage.vue';

import LoadingIcon from '~icons/mdi/loading';

const phoneService = inject(PHONE_SERVICE)!;

const { trackGAEvent } = useGoogleAnalytics();

const router = useRouter();

type ContactFormData = {
	name: string;
	email: string;
	phone: Phone;
	message: string;
};

type ContactSectionProps = ContactSection['config'] & {
	companyInformationIconsColor?: Color | null;
};

const props = withDefaults(defineProps<ContactSectionProps>(), {
	variant: 'default',
	overlayOpacity: 0,
	isParallaxEnabled: false,
	companyInformationIconsColor: null
});

const styles = useState<WebsiteStyles>('styles');

const domain = useState<string>('domain');

const formInitialState: ContactFormData = {
	name: '',
	email: '',
	phone: {
		number: '',
		countryCode: '+55'
	},
	message: ''
};

const company = useState<Company>('company');

const formData = reactive<ContactFormData>(copyObject(formInitialState));

const { isValidPhone, phoneMasks } = usePhoneInputValidation(
	() => formData.phone.number
);

const validationRules = computed(() => ({
	name: {
		required: helpers.withMessage('Este campo é obrigatório', required)
	},
	email: {
		required: helpers.withMessage('Este campo é obrigatório', required),
		email: helpers.withMessage('E-mail inválido', email)
	},
	phone: {
		required: helpers.withMessage('Este campo é obrigatório', required),
		phone: helpers.withMessage('Telefone inválido', isValidPhone)
	},
	message: {
		required: helpers.withMessage('Este campo é obrigatório', required)
	}
}));

const vuelidate = useVuelidate(validationRules, formData);

const overlay = computed<string>(() =>
	props.overlayOpacity ? `rgba(0,0,0, ${props.overlayOpacity})` : ''
);

const backgroundColor = computed<string>(() =>
	getCSSColorVar(props.backgroundColor || styles.value.appearance.background)
);

const textColor = computed<string>(() =>
	getCSSColorVar(props.textColor || styles.value.appearance.text)
);

const btnBackgroundColor = computed<Color>(
	() => props.buttonBackgroundColor || styles.value.appearance.background
);

const btnTextColor = computed<Color>(
	() => props.buttonTextColor || styles.value.appearance.text
);

const contactConfig = useContactConfig();

const contactItems = computed<ICompanyInformationItem[]>(() =>
	CompanyInformationPresenter.getInformationItems(
		contactConfig.value,
		company.value
	)
);

const { isSubmittingForm, recaptchaRoot, submitForm } = useFormWithRecaptcha({
	vuelidate,
	formInitialState,
	formData,
	onError: handleFormSubmitionError,
	formSubmitionFunction: submitFormData
});

const websiteConfig = useState<WebsiteConfig>('websiteConfig');

async function submitFormData({ recaptchaToken }: FormSubmitionFunctionParams) {
	const notyf = useNotyf();
	const activePropertyCommercialId = useState<null | string>(
		'activePropertyCommercialId'
	);

	const phoneNumber = phoneService.getPhoneValue(formData.phone.number);

	const dealData: RegisterNewDealParams = {
		...formData,
		title: formData.name,
		phone: formData.phone.countryCode + phoneNumber,
		companyDomain: domain.value,
		formName: 'Contato',
		reference: activePropertyCommercialId.value || null,
		campaignName: null
	};

	await $fetch('/api/contact-form', {
		method: 'POST',
		body: {
			captchaToken: recaptchaToken,
			captchaKey: websiteConfig.value.integrations.recaptchaV2Key || '',
			dealData
		},
		headers: { host: domain.value }
	});

	notyf.success('Mensagem enviada com sucesso!');

	await router.replace({
		query: {
			...router.currentRoute.value.query,
			contactFormSubmitted: 'true'
		}
	});

	const pageLocation = concatUrlPath(
		domain.value,
		router.currentRoute.value.fullPath
	);

	trackGAEvent('page_view', { page_location: pageLocation });
	trackGAEvent('submit_contact_form', dealData);
}

function handleFormSubmitionError() {
	const notyf = useNotyf();
	notyf.error('Erro ao enviar mensagem');
}

const backgroundSideVariant = computed<'left' | 'right' | 'default'>(() => {
	if (props.variant?.includes('background')) {
		const backgroundSide = props.variant.split('-')[1];
		return backgroundSide as 'left' | 'right';
	}

	return 'default';
});

const shouldShowContactDetails = computed<boolean>(
	() => props.variant === 'default'
);

const formTitleFontSize = computed<CssSize>(() => {
	if (props.variant === 'default') return '2.25rem';

	return '3.5rem';
});

const inputBorderColor = computed<string | 'inherit'>(() => {
	if (!props.inputBorderColor) return 'inherit';

	return getCSSColorVar(props.inputBorderColor || styles.value.appearance.text);
});

const $img = useImage();

const backgroundImageUrl = computed<string>(() => {
	if (!props.backgroundImage?.trim()) return '';

	// <!-- TODO: no futuro podemos usar o método $img.getSizes para otimizar mais a imagem, mas no momento a API desse método ainda é instável e pode mudar ou ser removida a qualquer momento -->
	const optimizedImageUrl = $img(props.backgroundImage, {
		width: 600,
		format: 'webp',
		quality: 80
	});

	return `url(${optimizedImageUrl})`;
});
</script>

<style lang="scss" scoped>
@mixin base-background {
	.overlay {
		.contact-background {
			display: none;
			max-width: 43%;
			width: 100%;
			background-size: cover;
			background-position: center;
			box-shadow: inset 0 0 0 2000px v-bind(overlay);
			background-size: cover;
			background-position: center;

			@include screen-up(lg) {
				display: flex;
			}

			&[data-has-background-image='true'] {
				background-image: v-bind(backgroundImageUrl);
			}
		}
		.contact-content {
			background-color: v-bind(backgroundColor);

			@include screen-up(lg) {
				padding-top: 6rem;
				padding-bottom: 6rem;
				padding-left: 5rem;
				padding-right: 5rem;
			}

			.contact-form {
				max-width: 100%;
			}
		}
	}
}

.contact-background {
	display: none;
}

[data-background-position='left'] {
	@include base-background;
	.overlay {
		flex-direction: row;
	}
	.contact-background {
		display: flex;
	}
}

[data-background-position='right'] {
	@include base-background;
	.overlay {
		flex-direction: row-reverse;
	}
}

.overlay {
	display: flex;
	background: v-bind(overlay);
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1;
}

.section-wrapper {
	width: 100%;
	background-size: cover;
	background-position: center;
	background-color: v-bind(backgroundColor);

	&[data-has-background-image='true'] {
		background-image: v-bind(backgroundImageUrl);
	}

	.contact-content {
		display: flex;
		width: 100%;
		color: v-bind(textColor);
		flex-direction: column;
		justify-content: center;
		gap: 2rem;
		padding-top: 4rem;
		padding-bottom: 4rem;

		@include screen-up(lg) {
			gap: 6rem;
			flex-direction: row;
			padding-top: 8rem;
			padding-bottom: 8rem;
		}

		.contact-company-label,
		p {
			text-transform: uppercase;
		}

		.contact-heading,
		.contact-form-title {
			font-size: 1.5rem;
			font-family: var(--heading-font);

			@include screen-up(lg) {
				font-size: v-bind(formTitleFontSize);
			}
		}

		.contact-company-label,
		.contact-subheading {
			font-size: 0.9rem;
			font-family: var(--default-font);

			@include screen-up(lg) {
				font-size: 1.15rem;
			}
		}

		p {
			font-size: 1rem;
		}

		.contact-column-separator {
			display: none;
			width: 1px;
			min-height: 100%;
			background-color: v-bind(textColor);
			opacity: 0.25;

			@include screen-up(lg) {
				display: block;
			}
		}

		.contact-information,
		.contact-form {
			display: flex;
			flex-direction: column;
			gap: 2rem;
			min-width: 30%;
			border-color: v-bind(textColor);
			color: v-bind(textColor);
			width: 100%;

			@include screen-up(lg) {
				max-width: 30%;
			}
		}

		.contact-information .company-information-items-wrapper {
			display: flex;
			flex-direction: column;
			gap: 2.5rem;
		}
	}
}

.contact-textarea,
.contact-input {
	transition: all 150ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
	width: 100%;
	padding: 1rem 0;
	color: inherit;
	border-style: solid;
	border-color: v-bind(inputBorderColor);
	background: none;
	font-size: 0.9rem;
	opacity: 0.6;

	&:focus {
		opacity: 1;
		outline: none;
	}
}

.contact-input {
	height: 1rem;
	border-bottom-width: 1px;
}

.contact-textarea {
	padding: 0.5rem;
	border-width: 1px;

	&::-webkit-scrollbar {
		width: 0.25rem;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 0.375rem;
		background-color: rgba(255, 255, 255, 0.5);
	}

	&::-webkit-scrollbar-track-piece {
		opacity: 0;
	}

	&::-webkit-scrollbar-track {
		opacity: 0;
	}

	&::-webkit-scrollbar-button {
		height: 0;
		width: 0;
	}
}

.invalid {
	input,
	select,
	textarea {
		border-color: var(--danger);
	}
}

.contact-recaptcha-message {
	opacity: 0.8;
}
</style>
